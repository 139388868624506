import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VAppBar,{attrs:{"app":"","color":"secondary","dark":"","height":"60px"}},[_c('div',{staticClass:"d-flex align-center justify-content-center"},[_c(VImg,{staticClass:"shrink mr-2",attrs:{"alt":"Diminished Studios Logo","contain":"","src":"https://i.imgur.com/mkzKScU.png","transition":"scale-transition","width":"50"}}),_c('span',{},[_vm._v("Diminished Studios")])],1),_c(VBtn,{staticClass:"ml-2",attrs:{"href":"","text":""},on:{"click":function($event){return _vm.$router.push('/')}}},[_c('span',{},[_vm._v("Home")])]),_c(VBtn,{staticClass:"ml-2",attrs:{"href":"","text":""},on:{"click":function($event){return _vm.$router.push('/Nomad')}}},[_c('span',{},[_vm._v("Nomad")])]),_c(VBtn,{staticClass:"ml-2",attrs:{"href":"","text":""},on:{"click":function($event){return _vm.$router.push('/NyranSurvivors')}}},[_c('span',{},[_vm._v("Nyran Survivors")])]),_c(VBtn,{staticClass:"ml-2",attrs:{"href":"","text":""},on:{"click":function($event){return _vm.openInNewTab('https://discord.gg/UcKJdYb4dt')}}},[_c('span',{},[_vm._v("Discord")])]),_c(VBtn,{staticClass:"ml-2",attrs:{"href":"","text":""},on:{"click":function($event){return _vm.$router.push('/Contact')}}},[_c('span',{},[_vm._v("Contact Us")])]),_c(VSpacer)],1),_c(VMain,[_c('router-view'),_c('Footer')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }