import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VContent';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContent,[_c(VFooter,{staticClass:"bottom absolute fixed footer",attrs:{"dark":""}},[_c(VRow,{staticClass:"d-flex justify-center mb-0 mt-0 pt-0"},[_c(VCol,{staticClass:"d-flex justify-center my-0 py-0",attrs:{"lg":"12"}},[_c(VCard,{staticClass:"d-flex flex-column align-center black--text my-0 py-0",attrs:{"elevation":"0","color":"transparent","dark":""}},[_c(VImg,{staticClass:"shrink mr-2",attrs:{"alt":"Diminished Studios Logo","contain":"","src":"https://i.imgur.com/mkzKScU.png","transition":"scale-transition","width":"70"}}),_c(VCardText,{staticClass:"white--text"},[_vm._v(" ©"+_vm._s(new Date().getFullYear())+" Diminished Studios. All Rights Reserved. All trademarks are the property of their respective owners. ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }