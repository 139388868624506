<template>
  <v-app>
    <v-app-bar app color="secondary" dark height="60px">
      <div class="d-flex align-center justify-content-center">
        <v-img alt="Diminished Studios Logo" class="shrink mr-2" contain src="https://i.imgur.com/mkzKScU.png"
          transition="scale-transition" width="50" />

        <span class="">Diminished Studios</span>
      </div>


      <v-btn href="" @click="$router.push('/')" text class="ml-2">
        <span class="">Home</span>
      </v-btn>

      <v-btn href="" @click="$router.push('/Nomad')" text class="ml-2">
        <span class="">Nomad</span>
      </v-btn>

      <v-btn href="" @click="$router.push('/NyranSurvivors')" text class="ml-2">
        <span class="">Nyran Survivors</span>
      </v-btn>

      <v-btn href="" @click="openInNewTab('https://discord.gg/UcKJdYb4dt')" text class="ml-2">
        <span class="">Discord</span>
      </v-btn>

      <v-btn href="" @click="$router.push('/Contact')" text class="ml-2">
        <span class="">Contact Us</span>
      </v-btn>

      

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view />
      <Footer></Footer>
    </v-main>

  </v-app>

</template>

<style>
body {
    background-color: #343a40;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%239C92AC' fill-opacity='0.25' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"), linear-gradient(to right top, #343a40, #2b2c31, #211f22, #151314, #000000);
}
</style>
<script>
import Footer from '../src/components/Footer.vue'
export default {
  name: 'App',
  components: {
    Footer,
  },
  methods: {
    openInNewTab(url) {
      window.open(url, '_blank', 'noreferrer');
    },
  },
};
</script>

<style>

</style>
