import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
      themes: {
        dark: {
            primary: '#fe9900',
            secondary: '#272729',
            accent: '#8c9eff',
            error: '#b71c1c',
          },
      },
    },
  })

  export default new Vuetify({
    theme: { dark: true },
  })

