import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

Vue.use(VueRouter)

Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})

const routes = [
  {
    path: '/',
    name: 'home',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Home.vue')
    }
  },
  {
    path: '/nomad',
    name: 'nomad',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Nomad.vue')
    }
  },
  {
    path: '/nyransurvivors',
    name: 'nyran survivors',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/NyranSurvivors.vue')
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Contact.vue')
    }
  },
   {
    path: '/:catchAll(.*)',
    redirect: '/',
    name: 'NotFound'
  },
  {
    path: '/(.*)*',
    name: '404',
    redirect: '/'
  },

]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router
