<template>
  <v-content>
    <v-footer dark class="bottom absolute fixed footer">
      <v-row class="d-flex justify-center mb-0 mt-0 pt-0">
        <v-col lg="12" class="d-flex justify-center my-0 py-0">
          <v-card
            class="d-flex flex-column align-center black--text my-0 py-0"
            elevation="0"
            color="transparent"
            dark
          >
          <v-img alt="Diminished Studios Logo" class="shrink mr-2" contain src="https://i.imgur.com/mkzKScU.png"
          transition="scale-transition" width="70" />
            <v-card-text class="white--text">
              ©{{ new Date().getFullYear() }}
              Diminished Studios. All Rights Reserved. All trademarks are the property of their respective owners.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-footer>
  </v-content>
  </template>
  
  <style scoped>
  .footer {
    font-size: 13px;
  }
  .footertitle {
    font-weight: bold;
    font-size: 15px;
    color: rgb(15, 15, 15);
  }
  </style>
  <script>
  export default {
    name: "Footer",
  
    data: function () {
      return {};
    },
  };
  </script>
  